import React,{Component} from 'react';

const TITLES = [
    'a software engineer',
    'a react app developer',
    'a Gamer',
    'Fast Learner',
    'an adventure seeker',
    'Team player'
];

class Title extends Component{
    state={ 
        titleIndex: 0,
        fadeIn:true
    };
    componentDidMount(){
        this.timeout=setTimeout(()=> this.setState({fadeIn:false}),2000)
        console.log('Title component');
        this.animateTitles();
    }
    componentWillUnmount(){
        console.log("Title unmount");
        clearInterval(this.titleInterval);
        clearTimeout(this.timeout);
    }
    animateTitles=()=>{
        this.titleInterval= setInterval(()=>{
            const titleIndex = (this.state.titleIndex + 1) % TITLES.length;
            this.setState({titleIndex, fadeIn: true});
            setTimeout(()=> this.setState({fadeIn:false}),2000)
        },4000)
    }
    render(){
        const{ fadeIn, titleIndex} =this.state;
        const title = TITLES[this.state.titleIndex];
        return(
            <p className={fadeIn? 'title-fade-in': 'title-fade-out'} >I am {title}</p>
        );
    }
}

export default Title;