import React, {Component} from 'react';
import profile from './assets/profile.png';
import Projects from './Projects';
import SocialProfiles from './SocialProfiles';
import Title from './Title';
class App extends Component{
    constructor() {
        super()
    
        this.state = {
             displayBio:false
        }
        // this.readMore=this.readMore.bind(this);
        // this.toggleDisplayBio= this.toggleDisplayBio.bind(this);
    }
    // readMore(){
    //     this.setState({displayBio: true});
        
    // }
    toggleDisplayBio=()=>{
        this.setState({displayBio:!this.state.displayBio});
    }
    render(){
        return(
            <div>

                <img src={profile} alt='profile' className='profile'/>
                <h1>Hello!</h1>
                <p> My name is Anuj Shakya. </p>
                {this.state.displayBio ? <Title />: null}
                <p>I,m also looking forward to working on meaningful projects.</p>
               {this.state.displayBio?(
            <div>
                <p> I live in Lagankhel</p>
                <p>My favorite language is JavaScript</p>
                <p>Beside coding I like momo</p>
                <button onClick={this.toggleDisplayBio}>show less</button>
            </div>
        ):(
            <div>
                <button onClick={this.toggleDisplayBio}>Read more</button>
            </div>

        )}
            
            <hr />
            <Projects/>
            <hr/>
            <SocialProfiles/>
            </div>
        );
    }
}

export default App;