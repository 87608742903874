import React, {Component} from 'react';
import PROJECTS from './data/projects';

class Project extends Component{
    render(){
        const{title, image, description, link}= this.props.project;
        return(
            // <div>{this.props.project.title}</div>
            <div style={{display: 'inline-block', width:300, margin:10}}>
            <h3>{title}</h3>
            <img src={image} alt="profile" style={{width: 200, height: 120}}/>
            <p>{description}</p>
            <a href={link}>{link}</a>
            </div>
            
        )
    }
}

class Projects extends Component{
    render(){
        return(
            <div>
                <h2>Highlighted Projects</h2>
                <div>
                    <div>
                        {
                            PROJECTS.map(PROJECT=>{
                                return(
                                    // <div key={PROJECT.id}>{PROJECT.title}</div>
                                    <Project key={PROJECT.id} project={PROJECT}/>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Projects;