import project1 from '../assets/project1.png';
import project2 from '../assets/project2.png';
import project3 from '../assets/project3.png';

const PROJECTS=[
    {
        id:1,
        title: 'Example React Application',
        description: 'A react app that I built, involving JS and core web dev concept',
        link:'https://github.com/15Dkatz/example',
        image: project1
    },
    {
        id:2,
        title: 'Example React Application',
        description: 'A react app that I built, involving JS and core web dev concept',
        link:'https://github.com/15Dkatz/example',
        image: project2
    },
    {
        id:3,
        title: 'Example React Application',
        description: 'A react app that I built, involving JS and core web dev concept',
        link:'https://github.com/15Dkatz/example',
        image: project3
    }
];


export default PROJECTS