import emailIcon from '../assets/email_icon.png';
import gihubIcon from '../assets/github_icon.png';
import linkedinIcon from '../assets/linkedin_icon.png';
import twitterIcon from '../assets/twitter_icon.png';

const SOCIAL_PROFILES=[
    {
        id: 1,
        link: 'mailto:anuzshakya007@gmail.com',
        image: emailIcon
    },
    {
        id: 2,
        link: 'https://github.com/Anuzshakya007',
        image: gihubIcon
    },
    {
        id: 3,
        link: 'https://www.linkedin.com/in/anuj-shakya-536b4616b/',
        image: linkedinIcon
    },
    {
        id: 4,
        link: 'https://www.facebook.com/anuj.shakya.94',
        image: twitterIcon
    },
];

export default SOCIAL_PROFILES;